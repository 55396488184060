<template>
  <v-container
    id="create-cours"
    fluid
    tag="section"
  >

    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div
              v-if="!$route.params.id"
              class="display-2 font-weight-light"
            >
              {{ $t('create-course') }}
            </div>
            <div
              v-if="$route.params.id"
              class="display-2 font-weight-light"
            >
              Mettre à jour le cours
            </div>
            <div class="subtitle-1 font-weight-light">
              Indiquez les informations
            </div>
          </template>

          <v-form
            ref="course"
            method="post"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    outlined
                    label="Intitulé *"
                    v-model="course.name"
                    @blur="createSlug"
                    hint="Exemple : Python avancé"
                    :rules="[v => !!v || 'Indiquez l\'intitulé', v => v.length <= 255 || 'Le texte est trop long']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    outlined
                    :items="themeCours"
                    item-text="name"
                    item-value="id"
                    label="Thème *"
                    v-model="course.theme.id"
                    :rules="[v => !!v || 'Indiquez le thème']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    outlined
                    label="Slug *"
                    v-model="course.slug"
                    hint="Exemple : python-avance"
                    :rules="[v => !!v || 'Indiquez le slug', v => v.length <= 255 || 'Le texte est trop long']"
                    spellcheck="false"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="Référence *"
                    v-model="course.reference"
                    hint="Ex. : PYA"
                    :rules="[v => !!v || 'Indiquez la référence', v => v.length <= 255 || 'Le texte est trop long']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <v-text-field
                    outlined
                    label="Durée (jours) *"
                    class="align-right no-arrows"
                    type="number"
                    suffix="jours"
                    v-model="course.nbDays"
                    :rules="[v => !!v || 'Indiquez la durée']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <v-text-field
                    outlined
                    label="Durée (heures) *"
                    class="align-right no-arrows"
                    type="number"
                    suffix="heures"
                    v-model="course.nbHours"
                    :rules="[v => !!v || 'Indiquez la durée']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <v-text-field
                    outlined
                    class="align-right no-arrows"
                    label="Prix par défaut (INTER) *"
                    type="number"
                    suffix="€"
                    hint="Prix hors taxe"
                    v-model="course.price"
                    :rules="[v => !!v || 'Indiquez le prix']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  lg="3"
                >
                  <v-text-field
                    outlined
                    class="align-right no-arrows"
                    label="Prix par défaut (INTRA)"
                    type="number"
                    suffix="€"
                    hint="Prix hors taxe"
                    v-model="course.priceIntra"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="7"
                >
                  <v-autocomplete
                    outlined
                    id="menu-code-nsf"
                    label="Code NSF"
                    v-model="course.codeNSF"
                    :items="codesNSF"
                    :item-text="item => item.numero + ' - ' + item.intitule"
                    item-value="id"
                    return-object
                    clearable
                    @change="formEdited = true"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    outlined
                    label="Fichier logo"
                    hint="Exemple : java.png"
                    v-model="course.img"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-switch
                    v-model="eligibleCPF"
                    label="Eligible CPF"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  v-if="eligibleCPF"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Code RNCP / RS"
                    type="text"
                    :rules="[v => v.length <= 10 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  v-if="eligibleCPF"
                  cols="12"
                  md="8"
                >
                  <v-text-field
                    outlined
                    label="URL Mon Compte Formation"
                    type="url"
                    hint="Laisser vide si pas éligible CPF"
                    v-model="course.cpf"
                    :rules="[v => v.length <= 255 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="course.description"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Public visé"
                    v-model="audienceString"
                    hint="Un public par ligne"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Prérequis"
                    v-model="prerequiesString"
                    hint="Un prérequis par ligne"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Objectifs"
                    v-model="objectivesString"
                    hint="Un objectif par ligne"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Prérequis techniques"
                    v-model="course.prerequiesTech"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Moyens pédagogiques"
                    v-model="course.moyensPedagogiques"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Modalités d'évaluation"
                    v-model="course.modalitesEvaluation"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Délais d'accès"
                    v-model="course.delaisAcces"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    outlined
                    label="Accessibilité"
                    v-model="course.accessibilite"
                    :rules="[v => v.length <= 65535 || 'Le texte est trop long']"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <h3>Plan de cours</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <tiptap-vuetify
                    id="course-plan"
                    v-model="course.plan"
                    :extensions="extensions"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    v-model="course.commentaires"
                    label="Commentaires"
                    hint="Les commentaires sont visibles en interne uniquement"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="flex-justify-end"
                >
                  <v-switch
                    :label="course.visible ? 'Cours public' : 'Cours privé'"
                    v-model="course.visible"
                    class="mr-6"
                  />
                  <v-btn
                    v-if="!$route.params.id"
                    color="primary"
                    class="mr-0"
                    type="submit"
                  >
                    {{ $t('create-the-course') }}
                  </v-btn>
                  <v-btn
                    v-if="$route.params.id"
                    color="primary"
                    class="mr-0"
                    type="submit"
                    @click="updateCourse()"
                  >
                    Mettre à jour le cours
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import slugify from 'slugify';
  import { mapMutations } from 'vuex';
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

  export default {

    components: { TiptapVuetify },

    data() {
      return {
        formEdited: false,
        themeCours: [],
        audienceString: '',
        prerequiesString: '',
        objectivesString: '',
        titres: [],
        tp: [],
        tpsString: '',
        snackName: '',
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3],
            },
          }],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak,
        ],
        codesNSF: [],
        course: {
          reference: '',
          name: '',
          description: '',
          theme: {
            id: '',
          },
          slug: '',
          img: '',
          cpf: '',
          codeNSF: null,
          plan: '',
          participantsMin: 2,
          participantsMax: 8,
          nbDays: '',
          nbHours: '',
          prerequies: [],
          audience: [],
          price: '',
          priceIntra: '',
          objectives: [],
          tps: [],
          prerequiesTech: '',
          moyensPedagogiques: '',
          modalitesEvaluation: '',
          delaisAcces: '',
          accessibilite: '',
          commentaires: '',
          visible: true,
        },
        eligibleCPF: false,
        indexLigne: 0,
      };
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    async created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/cours/' + this.$route.params.id)
          .then((res) => {
            this.course = res.data;
            this.audienceString = this.course.audience.join('\n');
            this.prerequiesString = this.course.prerequies.join('\n');
            this.objectivesString = this.course.objectives.join('\n');
            if (this.course.theme === null) {
              this.course.theme = { id: '' };
            }
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du cours', type: 'warn' });
          });
      }

      if (this.$route.params.course) {
        // copy mode
        const course = this.$route.params.course;
        this.course.name = course.name;
        this.course.theme = course.theme;
        this.createSlug();
        this.course.nbDays = course.nbDays;
        this.course.nbHours = course.nbHours;
        this.course.cpf = course.cpf;
        if (this.course.cpf) {
          this.eligibleCPF = true;
        }
        this.course.codeNSF = course.codeNSF;
        this.course.prerequies = course.prerequies;
        this.course.audience = course.audience;
        this.course.price = course.price;
        this.course.priceIntra = course.priceIntra;
        this.course.objectives = course.objectives;
        this.course.tps = course.tps;
        this.course.prerequiesTech = course.prerequiesTech;
        this.course.moyensPedagogiques = course.moyensPedagogiques;
        this.course.modalitesEvaluation = course.modalitesEvaluation;
        this.course.delaisAcces = course.delaisAcces;
        this.course.accessibilite = course.accessibilite;
        this.course.plan = course.plan;
        this.course.commentaires = course.commentaires
        this.course.visible = course.visible;
      }

      const res1 = await this.axios.get('/themeCours');
      this.themeCours = res1.data;
      this.themeCours.sort((a, b) => a.name.localeCompare(b.name));

      this.axios.get('/codesNSF')
        .then((res) => {
          this.codesNSF = res.data;
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des codes NSF', type: 'warn' });
        });
    },

    methods: {
      createSlug() {
        if (!this.course.slug) {
          const slug = slugify(this.course.name, { lower: true });
          this.axios.get('/cours/slugExists/' + slug)
            .then((res) => {
              if (res.data) {
                // le slug existe déjà
                this.course.slug = slug + '-copie';
              } else {
                // le slug n'existe pas
                this.course.slug = slug;
              }
            });
        }
      },

      validate() {
        this.formEdited = false;
        if (this.$refs.course.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateCourse();
          } else {
            // create mode
            this.createCourse();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createCourse() {
        this.course.audience = this.audienceString.split('\n');
        this.course.prerequies = this.prerequiesString.split('\n');
        this.course.objectives = this.objectivesString.split('\n');

        this.axios.post('/cours', this.course)
          .then(res => {
            this.setSnack({ text: 'Le cours a bien été ajouté au catalogue', type: 'success' });
            this.$router.push({ name: 'CoursDetail', params: { id: res.data.id } });
            return this.success;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la création du cours', type: 'warn' });
            this.fail = true;
          })
          .finally(() => this.loadingRequest);
      },

      updateCourse() {
        this.course.audience = this.audienceString.split('\n');
        this.course.prerequies = this.prerequiesString.split('\n');
        this.course.objectives = this.objectivesString.split('\n');

        this.axios.put('/cours/' + this.course.id, this.course)
          .then(response => {
            this.setSnack({ text: 'Le cours a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'CoursDetail', params: { id: this.course.id } });
            return this.success;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour du cours', type: 'warn' });
            this.fail = true;
          })
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }

</script>

<style scoped>

  #create-cours >>> .flex-justify-end {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  #create-cours >>> .align-right,
  #create-cours >>> .align-right input {
    text-align: right;
  }

  #create-cours >>> #divider {
    position: relative;
  }

  #create-cours >>> .gras input {
    font-weight: 600;
  }

  #create-cours >>> .italique input {
    font-style: italic;
  }

  #create-cours >>> .v-dialog__content {
    z-index: 10000 !important;
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet {
    position: relative;
    border: solid 1px rgba(0, 0, 0, 0.42);
    border-radius: 4px;
    box-sizing: border-box;
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet:hover {
    border: solid 1px rgba(0, 0, 0);
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet:focus-within {
    border: solid 2px rgb(91, 67, 203);
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet .tiptap-vuetify-editor__toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet .tiptap-vuetify-editor__content {
    max-height: 250px;
    transition: max-height 0.5s;
  }

  #create-cours >>> .tiptap-vuetify-editor .v-sheet .tiptap-vuetify-editor__content:focus-within {
    max-height: 100vh;
  }
</style>
